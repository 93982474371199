import { Box, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import { COLORS } from '../../../../constants/colors';

const useStyles = makeStyles({
    iconContainer: {
        '& > svg': {
            fontSize: '2.3rem',
            color: COLORS.SLATE_GREY,
        },
    },
});

interface NotTrackedComponentProps {
    headerText: string;
    description: string;
    icon: React.ReactElement;
}

const FlightNotTrackedComponent = ({ headerText, description, icon }: NotTrackedComponentProps) => {
    const classes = useStyles();

    return (
        <Grid
            data-testid="flight-not-tracked"
            width="100%"
            textAlign="center"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            <Box className={classes.iconContainer}>{icon}</Box>
            <Typography fontWeight="700" color={COLORS.SLATE_GREY}>
                {headerText}
            </Typography>
            <Typography color={COLORS.SLATE_GREY}>{description}</Typography>
        </Grid>
    );
};

export default FlightNotTrackedComponent;
