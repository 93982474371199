import { Grid, Skeleton, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
    mainWrapper: {
        alignItems: 'center',
        padding: 0,
        marginLeft: '1rem',
    },
    mainWrapperTopPadding: { paddingTop: '3.5rem' },
    headerTitleWrapper: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column',
    },
    headerTitle: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 0,
    },
}));

const DocumentsLoadingSkeleton = () => {
    const classes = useStyles();

    return (
        <Grid container className={classes.mainWrapper} data-testid="documents-loading-skeleton">
            <Grid item xs={12} className={classes.headerTitleWrapper}>
                <Grid item className={classes.headerTitle}>
                    <Skeleton variant="rectangular" width="200px" height={40} />
                </Grid>
            </Grid>

            {[1, 2, 3, 4, 5].map(item => (
                <Grid container sx={{ marginTop: '2rem' }} key={item}>
                    <Grid item xs={12} md={12} xl={12}>
                        <Skeleton variant="rectangular" width="100%" height={50} />
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
};

export default DocumentsLoadingSkeleton;
