import { Tooltip, Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    getFlightStatusBackgroundColor,
    getFlightStatusKeyName,
    getFlightStatusTooltip,
    getFlightStatusColor,
    getFlightStatusBorderColor,
} from '../helpers/flightStatusUtil';

interface StatusButtonStyleProps {
    variant: string;
}

const useStyles = makeStyles({
    statusButton: ({ variant }: StatusButtonStyleProps) => ({
        padding: '0.25rem 0.5rem !important',
        borderRadius: '4px',
        marginLeft: '0.5rem',
        color: getFlightStatusColor(getFlightStatusKeyName(variant)),
        border: `1px solid ${getFlightStatusBorderColor(getFlightStatusKeyName(variant))}`,
        backgroundColor: getFlightStatusBackgroundColor(getFlightStatusKeyName(variant)),
    }),
    textButton: {
        fontSize: '.75rem',
        lineHeight: '.9rem',
    },
});

interface StatusButtonProps {
    variant: string;
    className?: string;
}

export const FlightStatusButton = ({ variant, className }: StatusButtonProps) => {
    const classes = useStyles({ variant });
    const tooltip = getFlightStatusTooltip(getFlightStatusKeyName(variant));
    const { t } = useTranslation();

    return (
        <Tooltip title={tooltip} placement="top" arrow>
            <Box className={clsx(className, classes.statusButton)}>
                <Typography className={classes.textButton} data-testid="flight-status">
                    {variant !== '' ? t(`pages.singleRide.flightInformation.statuses.${variant}`) : ''}
                </Typography>
            </Box>
        </Tooltip>
    );
};

export default FlightStatusButton;
