import { Spinner } from '@get-e/react-components';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { COLORS } from '../../../constants/colors';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import downloadDocument from '../api/downloadDowument';
import { DocumentItem, DocumentUrlKey, FileItem } from '../api/types';

const useStyles = makeStyles(styleTheme => ({
    row: {
        padding: '.75rem',
        cursor: 'pointer',
        [styleTheme.breakpoints.down('sm')]: { width: '90%' },
    },
    main: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingRight: '0.5rem',
        '&:hover': {
            backgroundColor: COLORS.LIGHT_GRAY,
            cursor: 'pointer',
        },
    },
    downloadLink: {
        padding: '.75rem',
        color: COLORS.BLUE_DARK,
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        borderColor: 'transparent !important',
    },
    downloadIcon: { cursor: 'pointer' },
}));

interface DocumentRowProps {
    item: DocumentItem | FileItem;
}

const DocumentRow = ({ item }: DocumentRowProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { showNotification } = useNotificationContext();

    const { mutate: downloadDocumentMutation, isLoading } = useMutation(downloadDocument);

    const handleItemClick = () => {
        if (!(DocumentUrlKey in item)) {
            downloadDocumentMutation({
                id: item.id.toString(),
                fileName: item.label,
                onSuccess: () => {
                    showNotification(t('pages.documents.downloadSuccess'), Severity.Info);
                },
                onError: () => {
                    showNotification(t('pages.documents.downloadFailed'), Severity.Error);
                },
            });
        } else {
            window.open(item[DocumentUrlKey], '_blank')?.focus();
        }
    };

    return (
        <Grid container className={classes.main} onClick={handleItemClick} data-testid="document-row">
            <Grid item alignItems="baseline" className={classes.row}>
                {item.label}
            </Grid>
            {isLoading ? <Spinner size={24} /> : <DownloadOutlinedIcon className={classes.downloadIcon} />}
        </Grid>
    );
};

export default DocumentRow;
